    <template>
        <v-container class="mt-10" id="tools">
            <h1>Tools</h1>
            
    <p>Easy way to convert midi files to fps txt, csv and Afx jsx files. <br>
      We made this tool to create an easy workflow for syncing audio we make <br>
      to frame based tools we use. You are free to use it as long as you like.
    </p>

    <h3>MPFTLE: New release (0.3) coming soon!</h3>

                                    <p>If for any reason you need help because you are in the middle of a project? Contact us via e-mail and we can help you with temporarily using Mpftle 0.2.2</p>
                                    <li>3dsmax support</li>
                                    <li>ADSR</li>
                                    <li>Future proof</li>
                                    <li>Optimised code, which makes MPFTLE run fast!</li>
<br>
                                    <p>For now you can still use</p>

    <router-link to="/mpftle">
      <a>MPFTLE - beta v0.2.1</a>
    </router-link>

        </v-container>
    </template>
    <script>
    import release01 from "@/assets/releases/01.png"
    import release02 from "@/assets/releases/02.png"

export default {

    data() {
        return {
            releases: [
                {
                    Title: 'Placeholder',
                    Image: release01,
                    Url: 'https://youtu.be/M4eBOYqrx1w'
                },
                {
                    Title: 'Placeholder',
                    Image: release02,
                    Url: 'https://youtu.be/v7nc-R08orA'
                },
                
                
            ]
        }      
    },
    methods: {
        goUrl(url) {
            window.open( url, '_blank');
        }
    },
}
</script>

<style>

    .clickable {
        cursor: pointer;
    }

</style>
