import Vue from 'vue'
import Router from 'vue-router'


import Mpftle from '../components/Mpftle/Home.vue'
import Home from '../components/Home/HomePage.vue'

import Signin from '../components/Account/Signin.vue'
import Signup from '../components/Account/Signup.vue'
import Signout from '../components/Account/Signout.vue'
import Edit from '../components/Account/Edit.vue'
import Forgotpwd from '../components/Account/ForgotPassword.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/mpftle',
      name: 'mpftle',
      component: Mpftle
    },

    // ACOUNT ROUTING
    {
        path: '/account/signin',
        name: 'signin',
        component: Signin
      },
      {
        path: '/account/signup',
        name: 'signup',
        component: Signup
      },
      {
        path: '/account/forgotpwd',
        name: 'forgotpwd',
        component: Forgotpwd
      },
      {
        path: '/account/signout',
        name: 'signout',
        component: Signout
      },
      {
        path: '/account/Edit',
        name: 'edit',
        component: Edit
      },

  ]
})
