<template>
    <v-container id="contact">
        
        <v-row>
            <v-col class="mt-10" cols="12" sm="4">
                <h1>Bas</h1>
                <v-card flat>
                    <v-img src="@/assets/about/bas.k.2.png" class="profile-pic-rounded"/>
                    <p>Excessive experienced regarding audiovisualisation for live performances worldwide. By grabbing the house/techno scene in its essence, sonically and visually, during the early 90’s, has developed a sixth sense for empowerment and creating a sophisticated ambience. <a href="">work@factorysettings.net</a></p>
                    
                </v-card>
            </v-col>
            <v-col class="mt-10" cols="12" sm="4">
                <h1>Stefan</h1>
                <v-card flat>
                    <v-img src="@/assets/about/stefan.k.2.png" class="profile-pic-rounded"/>
                    <p>With a background in architecture and media studies, couldn’t stay within the realm of music production only. A crash course decennium regarding audiovisual design, filled the gaps with 3D modelling skills and programming environments for finally giving shape to long lasting ideas. <a href="">stefan@factorysettings.net</a></p>
                    
                </v-card>
            </v-col>
            <v-col class="mt-10" cols="12" sm="4">
                <h1>Bas</h1>
                <v-card flat>
                    <v-img src="@/assets/about/bas.e.2.png" class="profile-pic-rounded"/>
                    <p>Skilled concept and interactive installation artist who doesn't waver around the unimaginable. With a steep reportcard regarding user generated content experiences and applied coding proficiency, quickly achieving enthusiasm and advocacy for any kind of application. <a href="">bas@factorysettings.net</a></p>
                    
                    

                    
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {}
</script>
<style scoped>
.profile-pic-rounded {
    border-radius: 50% !important;
    
    border: 40px solid #fff;
}
p {
    font-size: 12px;
    margin: 0 20px;
    text-align: left !important;
    font-style: italic;
}


p {
    text-indent: 12%;
}
</style>