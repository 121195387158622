<template>
  <v-container>

    
    <v-row class="text-center" >
      <v-col >

        <v-img src="@/assets/factorysettings.png" align-content-center width="35%" class="center mt-10" />
        
        <VideoPlayer class="pt-12 rbg" />
        
        <JoinUs />
        <SocialLinks/>
        
        <ProjectOverview class="bbg"/>
        <ReleasesOverview class="bbg"/>
        <ToolsOverview class="bbg"/>

        <About />
        <Team />

        <CustomerLogos class="mb-10"/>
        

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoPlayer from './VideoPlayer'
import ProjectOverview from './ProjectOverview'
import ReleasesOverview from './ReleasesOverview'
import ToolsOverview from './ToolsOverview'
import CustomerLogos from './CustomerLogos'
import JoinUs from './JoinUs'
import SocialLinks from './SocialLinks'
import About from './About'
import Team from './Team'

  export default {
    name: 'HomePage',
    
    components: {
      VideoPlayer,
      ProjectOverview,
      ReleasesOverview,
      ToolsOverview,
      CustomerLogos,
      JoinUs,
      SocialLinks,
      About,
      Team
    },

    data: () => ({
      counter: 1
    }),
  }
</script>
<style scoped>
  p {
    margin-top: 30px;
    font-size: 16px;
  }
  h1 {
    margin-top: 30px;
  }
  .center {
    text-align: center;
    margin: 0 auto;
  }
  .rbg {
    /* background-color: red;
     */
     margin: 0;
     padding: 0;

  }
  .bbg {
    /* background-color:blue; */
    margin: 0;
    padding: 0;
  }
</style>