<template>
    <div>
        <div style="padding: 0 0 150px 0">
        <h1 class="mt-10">Clients</h1>
        <v-img src="@/assets/ext-logos/all.png" width="50%" style="margin: 0  auto" />
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style>

</style>