<template>
    <span class="edit-header">BPM:
        <input class="edit-input" type="number" v-model="bpm" min="1" max="300">
    </span>
</template>

<script>
    export default {
        name: 'BaseBpm',
        computed: {
            bpm: {
                get() {
                    return this.$store.state.bpm
                },
                set(value) {
                    this.$store.commit('updateBpm', value)
                }
            }
        }
    }
</script>