    <template>
        <v-container class="mt-10" id="projects">
            <h1>Tutorials</h1>
            <v-row>
                <v-col v-for="n in projects" :key="n" cols="12" sm="4">
                    <v-card flat>
                        
                        <v-img :src="n.Image" @click="goUrl(n.Url)" class="clickable"/>
                         
                        <!-- <v-img :src="''n.Image" /> -->
                    </v-card>
                </v-col>
            </v-row>
            <!-- <v-btn text>...Show me more!</v-btn> -->
        </v-container>
    </template>
    <script>

    import project01 from "@/assets/projects/01.png"
    import project02 from "@/assets/projects/02.png"
    import project03 from "@/assets/projects/03.png"
    import project04 from "@/assets/projects/04.png"
    import project05 from "@/assets/projects/05.png"
    import project06 from "@/assets/projects/06.png"


    export default {
        data() {
            return {
                projects: [
                    {
                        Title: 'Placeholder',
                        Image: project01,
                        Url: 'https://youtu.be/zrA9gaCymjM'
                    },
                    {
                        Title: 'Placeholder',
                        Image: project02,
                        Url: 'https://youtu.be/_MkC8P5JQY0'
                    },
                    {
                        Title: 'Placeholder',
                        Image: project03,
                        Url: 'https://youtu.be/bLgxQlNejMM'
                    },
                    {
                        Title: 'Placeholder',
                        Image: project04,
                        Url: 'https://youtu.be/u9G5mzAYb48'
                    },
                    {
                        Title: 'Placeholder',
                        Image: project05,
                        Url: 'https://youtu.be/xDhbfGvtA8w'
                    },
                    {
                        Title: 'Placeholder',
                        Image: project06,
                        Url: 'https://youtu.be/-vf61_Vcc9c'
                    },
                    
                ]
            }
        },
        methods: {
            goUrl(url) {
                window.open( url, '_blank');
            }
        },
    }
    </script>
    <style>
    </style>
