    <template>
        <v-container class="mt-10" id="releases">
            <h1>Releases</h1>
            <v-row>
                <v-col v-for="n in releases" :key="n" cols="12" sm="6">
                    <v-card flat >
                        <v-img :src="n.Image" @click="goUrl(n.Url)" class="clickable" />
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </template>
    <script>
    import release01 from "@/assets/releases/01.png"
    import release02 from "@/assets/releases/02.png"

export default {

    data() {
        return {
            releases: [
                {
                    Title: 'Placeholder',
                    Image: release01,
                    Url: 'https://youtu.be/M4eBOYqrx1w'
                },
                {
                    Title: 'Placeholder',
                    Image: release02,
                    Url: 'https://youtu.be/v7nc-R08orA'
                },
                
                
            ]
        }      
    },
    methods: {
        goUrl(url) {
            window.open( url, '_blank');
        }
    },
}
</script>

<style>

    .clickable {
        cursor: pointer;
    }

</style>
