<template>
  <div>
        <div>
        <v-card max-width="344" class="mx-auto sign-up-card">
            
        <v-card-title class="mx-auto">
            <strong>REGISTER NEW USER</strong>    
        </v-card-title>

            <v-card-text>    
                <v-form v-on:keyup.enter="signUp">
                    <v-container>
                        <v-layout column>
                            <v-flex xs12>
                                <v-text-field v-model="email" label="E-mail" required></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-on:keyup.enter="signUp" v-model="password" :rules="[rules.required]"
                                    :type="'password'" name="input-10-1" label="Password" hint="At least 6 characters">
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-btn color="success" dark @click="signUp">REGISTER</v-btn>
                        <br>
                        <br>
                        <p>Have an account? Sign in <router-link to="/account/signin">here</router-link>
                        </p>
                    </v-container>
                </v-form>
        


                
        
            </v-card-text>
        </v-card>

        <img height="60px" src="https://firebase.google.com/downloads/brand-guidelines/SVG/logo-built_white.svg" alt="" style="margin-top:10px;">

    </div>
  </div>
</template>
<script<script>
    import {
        mapGetters
    } from 'vuex'
    import Firebase from 'firebase'
    export default {
        name: 'SignUpForm',
        computed: {
            ...mapGetters(['user']),
            nextRoute() {
                return this.$route.query.redirect || '/account/edit'
            }
        },
        data() {
            return {
                email: '',
                password: '',
                rules: {
                    required: value => !!value || 'Required.',
                    emailMatch: () => ('The email and password you entered don\'t match')
                }
            }
        },
        watch: {
            user(auth) {
                if (!!auth) {
                    this.$router.replace(this.nextRoute)
                }
            }
        },
        methods: {
            async signUp() {
                await this.$auth.signup(this.email, this.password)
                .then(function() {
                    // Password reset email sent.
                    alert('Registration succesfull.')
                })
                .catch(function(error) {
                    // Error occurred. Inspect error.code.
                    alert('An error occured: ' + error )
                });
            },

            
            keymonitor: function (event) {
                console.log(event.key);
                if (event.key == "Enter") {
                    console.log('the id of the input was:' + event.currentTarget.id);
                    console.log("enter key was pressed!");
                }
            }
        },
    }
</script>
<style scoped>
.error-note {
    color: #999;
    font-size: 12px;
}
.sign-up-card {
    background-color: #fff;
  }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 2s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
        transform: translateX(400px);
        opacity: 0;
    }
</style>