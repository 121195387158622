<template>
<div>
    <span class="noteTrackHeader">
            TR: {{ index + 1 }} : edit
        </span>
    <span class="frameBlock" v-for="(track, index) in trackList" :key="index" align-end
                                justify-start flex-nowrap fill-height>
                                <span class="frameValue"
                                    v-bind:style="{ height: (track / 100 * 40) + 'px', marginTop: (40 - (track / 100 * 40) )+ 'px' }">
                                </span>
                            </span>
                            </div>
</template>
<script>
export default {
    name: 'BaseTrack',
    props: {
        index: Number,
        trackList: Array
    },
    computed: {
        bpm: {
            get() {
                return this.$store.state.bpm
            },
            set(value) {
                this.$store.commit('updateBpm', value)
            }
        }
    }
}
</script>