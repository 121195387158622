<template>
    <v-row>
                    <v-col>
                        <v-card color="grey-2" dark tile flat>
                            <v-card-text class="text-sm-left">
                                <!-- <span v-html="mpftleLog"></span> -->
                                <v-textarea name="input-7-1" flat dark disabled auto-grow :value=mpftleLog
                                    hint="Console output">
                                </v-textarea>
                                
                                <div v-if="true">
                                    <h3>New release (0.3) coming soon!</h3>

                                    <p>If for any reason you need help because you are in the middle of a project? Contact us via e-mail and we can help you with temporarily using Mpftle 0.2.2</p>
                                    <li>3dsmax support</li>
                                    <li>ADSR</li>
                                    <li>Future proof</li>
                                    <li>Optimised code, which makes MPFTLE run fast!</li>
                                    
                                    
                                    <br>
                                    <h3>Updated:</h3>
                                        
                                        
                                        Changes 0.2.2 (02/03/2020)
                                        <li>Blender Output support</li>
                                        <li>Updated Vuetify</li>
                                        <li>Some layout broke (will fix soon)</li>
                                        <br>
                                        Changes 0.2.1 (30/08/2019)
                                        <li>Midi CC support</li>
                                        <li>Attack - is the frames taken for initial run-up of level from nil to peak,
                                            beginning when the key is pressed.</li>
                                        <li>Decay - is the frames taken for the subsequent run down from the attack
                                            level to the designated sustain level.</li>
                                        <li>Sustain - is the value during the main sequence of the duration, until the
                                            midi key is released.</li>
                                        <li>New download file screen, preparing for more options</li>
                                        <li>More compact User interface</li>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
</template>
<script>
export default {
    name: 'BaseLog',
    props: {
        mpftleLog: String
    },
    computed: {
        bpm: {
            get() {
                return this.$store.state.bpm
            },
            set(value) {
                this.$store.commit('updateBpm', value)
            }
        }
    }
}
</script>
