import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import './plugins'
import store from './store'
// import VueRouter from 'vue-router'
import router from './router'
import FirebaseAuthPlugin from './FirebaseAuthPlugin'

// Vue.use(VueRouter)
Vue.use(FirebaseAuthPlugin)

Vue.config.productionTip = false

// sync(store, router)

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

