<template>
<v-card>
  <div>
    <h3>ACCOUNT</h3>
    <p>User e-mail: {{ user.email }}</p>
    <!-- <p>Edit account (working on)</p> -->
    <!-- <p>User database record</p> -->
    <!-- <router-link v-show="!!user" to="/account/signout">Sign out</router-link>  -->
    <v-btn color="success" dark to="/account/signout">Sign out</v-btn>
    <br>

    <div  v-if="!user.emailVerified" >
      <v-btn color="success" dark @click="verifyEmail">Verify Email</v-btn>
    <br>

    </div>
    
<!-- <p>{{ user.emailVerified }}</p> -->
<br><br><br>




<h3>DELETE YOUR ACCOUNT</h3>
<v-layout justify-center>
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        
        <v-btn color="red" dark v-on="on">Delete account</v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>By clicking on delete, your account will be deleted. For ever!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark text @click="dialog = false">No</v-btn>
          <v-btn color="red" text dark @click="deleteAccount()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>


  </div>
</v-card>
</template>
<script>
  import {
    mapGetters
  } from 'vuex'
  export default {
    data() {
      return {
        dialog: false
      }
    },
    computed: {
      ...mapGetters(['user']),
    },
    methods: {

      async verifyEmail() {
        await this.$auth.verifyEmail()
          .then(function() {
            // Password reset email sent.
            alert('Verification mail send')
          })
          .catch(function(error) {
            // Error occurred. Inspect error.code.
            alert('An error occured: ' + error )
          });
      },
      async deleteAccount() {
        var _this = this
        await this.$auth.deleteAccount()
          .then(function() {
            // Password reset email sent.
            alert('User deleted')
            _this.$router.replace({name: 'signout'})
            
          })
          .catch(function(error) {
            // Error occurred. Inspect error.code.
            alert('An error occured: ' + error )
          });

          
      },
    }
  }
</script>