<template>
    <v-container class="mt-10" id="about">
        <v-row no-gutters>
            <v-col cols="12" sm="6" offset-sm="3" centered>
                <h1>About Factory Settings</h1>
                <p>
                    Including, Bas, Bas & Stefan. We are a dutch group of friends with a long history in electronic
                    music,
                    graphical art, VJ-ing and coding. We would like to share all our knowledge with the world. We
                    believe that
                    our skills are only valuable if it reaches others for inspiration and personal growth.
                </p>
                <p>
                    Our tutorials online will always be free to watch. Eagerness, drive and investing time in really
                    learning
                    something should be rewarded.
                </p>
                <p>
                    To give you an idea what to expect regarding software use, our skills lies within:
                    Touchdesiger, 3dsMax, Blender, Ableton, MaxMsp, Resolume, Python, After Effects, Substance Painter,
                    Syntheyes, CSV, Kinect
                </p>
                <p>
                    We'll focus on software with non-commercial licenses and/or with an open source state of mind as
                    much as
                    possible, so everyone is able to follow along!
                </p>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
    export default {}
</script>
<style scoped>
.profile-pic-rounded {
    border-radius: 50% !important;
    
    border: 40px solid #fff;
    
}
p {
        /* font-size: 14px; */
        /* text-align: left; */
    }
</style>