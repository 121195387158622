<template>
<div>
    <div class="wrapper">
        <!-- <v-img src="@/assets/thumbs/placeholder.png" width="100%"/> -->
        <iframe 
            :width="vimeoWidth" 
            :height="vimeoHeight"
            src="https://player.vimeo.com/video/393662686" 
            frameborder="0" 
            allow="autoplay; fullscreen" 
            allowfullscreen />
    </div>        
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        vimeoWidth() {
            return this.$vuetify.breakpoint.width * 0.8

        },
        vimeoHeight() {
            return ( this.vimeoWidth / 16 ) * 9
        }
    },
}
</script>

<style scoped>

    .wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

</style>