<template>
  <v-app>
    <template>
      <div v-if="true">
        <v-system-bar window dark class="fixed-nav">
          <!-- <v-icon>mdi-message</v-icon> -->
          
          <div v-if="$route.path == '/mpftle'">
            <router-link to="/" >Back</router-link>
          </div>

          <div v-else>
            <span><a class="fancyShiny" @click="goTo(0)">Factory Settings</a></span>
            <span>&nbsp;<a @click="goTo('#projects')" >Tutorials</a>  </span>
            <span>&nbsp;<a @click="goTo('#releases')" >Releases</a>  </span>
            <span>&nbsp;<a @click="goTo('#tools')" >Tools</a>  </span>
            <span>&nbsp;<a @click="goTo('#about')" >About</a>  </span>
            <span><a @click="goTo('#contact')">Contact</a>  </span>
            <v-spacer></v-spacer>
          </div>
          

          
        </v-system-bar>
      </div>
    </template>
      <!-- <Mpftle  class="mt-8"/> -->
    <v-content class="mt-3">
      <!-- <HomePage /> -->
      <router-view />
    </v-content>
  </v-app>
</template>
<script>
  import HomePage from './components/Home/HomePage';
  import Mpftle from './components/Mpftle/Home';
  
  export default {
    name: 'App',
    components: {
      HomePage,
      Mpftle
    },
    data: () => ({
      //
    }),
    methods: {
      goTo(anchor) {
        this.$vuetify.goTo(anchor, { offset: 40, duration: 1000})
      }
    },
  };
</script>
<style scoped>
  span {
    color: #fff;
    /* margin: 0 10px 0 0px; */
  }
  a {
    text-decoration: none;
    color: #fff !important;
    margin: 0  8px;
    font-weight: bold;
    font-size: 12px;
  }
  .fixed-nav {
    position: fixed;
    /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
    left: 0;
    /* top left corner should start at leftmost spot */
    top: 0;
    /* top left corner should start at topmost spot */
    width: 100vw;
    /* take up the full browser width */
    z-index: 200;
    /* high z index so other content scrolls underneath */
    height: 100px;
    /* define height for content */
  }


/* The issue is that you made the animation to infinite so when it will end it will restart immediately for the initial state. To avoid this you can add alternate to the animation: */

.fancyShiny {
  background: linear-gradient(to right, white ,rgb(65, 63, 0), white 20%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-fill-color: transparent; */
  background-size: 268% auto;
  animation: textShine 7s ease-in-out infinite alternate;
}

@keyframes textShine {
  to {
    background-position: 100%;
  }
}
</style>