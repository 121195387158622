import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  user: null,
  progress: 5,
  totalDownloads: null,

  bpm: 125,
  fps: 30,
  a: 10,
  isLoading: false,

  file: 'geen'
}

const mutations = {
  updateUser(state, {
    user
  }) {
    Vue.set(state, 'user', user)
  },

  updateBpm: (state, value) => state.bpm = value,
  updateFps: (state, value) => state.fps = value,

  setLoadingFalse: (state) => state.isLoading = false,
  setLoadingTrue: (state) => state.isLoading = true,
  
  updateA: (state, value) => state.a = value,
  
  UPDATE_PROGRESS(state) {
    state.progress = 10
  }
}

const actions = {

  updateProgress({
    commit
  }) {
    commit('UPDATE_PROGRESS')
  }
}

const getters = {
  user: state => state.user,
  progress: state => state.progress,
  bpm: state => state.bpm,
  fps: state => state.fps,
  isLoading: state => state.isLoading,
  file: state => state.file
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

export default store