<template>
    <div class="Social mts-10"><br><span><a href="https://www.patreon.com/factorysettings" target="outside"
                class="mx-1">
                Patreon </a></span><span><a href="https://www.youtube.com/channel/UC-L5_fXV7yul7YtIMz_d2eA/"
                target="outside" class="mx-1">
                Youtube </a></span><span><a href="https://www.facebook.com/Factory-Settings-2043471125951100/"
                target="outside" class="mx-1">
                Facebook </a></span><span><a href="https://discord.gg/6saHP7j" target="outside" class="mx-1">
                Discord </a></span><span><a href="https://www.instagram.com/daily.typo" target="outside"
                class="mx-1">
                Instagram </a></span></div>
</template>

<script>
    export default {

    }
</script>

<style>

</style>