<template>
    <div>
        <!-- <p>File: ..{{ file }}</p> -->
        
        
    </div>
</template>
<script>
export default {
    name: 'BaseFilepicker',
    computed: {
        file: {
            get() {
                return this.$store.state.file
            },
            set(value) {
                this.$store.commit('updateFile', value)
            }
        }
    }
}
</script>
