<template>
    <span class="edit-header">FPS: 
        <input class="edit-input" type="number" v-model="fps" min="1" max="300">
    </span>
</template>

<script>
    export default {
        name: 'BaseFps',
        computed: {
            fps: {
                get() {
                    return this.$store.state.fps
                },
                set(value) {
                    this.$store.commit('updateFps', value)
                }
            }
        }
    }
</script>