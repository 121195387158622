<template>
  <div>

    <v-card max-width="344" class="mx-auto sign-in-card">
      
      <v-card-title>
        <strong>SIGN IN </strong>          
      </v-card-title>

      <v-card-text>
        <v-form v-on:keyup.enter="signIn">
          <v-container row>
            <v-layout column>
              <v-flex xs12>
                <v-text-field v-model="email" label="E-mail" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="password" :type="'password'" name="input-10-1" label="Password"
                  hint="At least 6 characters"></v-text-field>
              </v-flex>
            </v-layout>
            <v-btn color="success" dark @click="signIn">SIGN IN</v-btn>
            <br><br>
            <p>No account? <router-link to="/account/signup">Register here</router-link>! <br>
            Or maybe you <router-link to="/account/forgotpwd">forgot your password</router-link>?
            </p>
          </v-container>
        </v-form>

      </v-card-text>

    </v-card>

    <img height="60px" src="https://firebase.google.com/downloads/brand-guidelines/SVG/logo-built_white.svg" alt="" style="margin-top:10px;">
    
  </div>
</template>
<script>
  import {
    mapGetters
  } from 'vuex'
  export default {
    computed: {
      ...mapGetters(['user']),
      nextRoute() {
        return this.$route.query.redirect || '/mpftle'
      }
    },
    data() {
      return {
        password: '',
        email: ''
      }
    },
    components: {},
    watch: {
      user(auth) {
        // console.log('Auth changed')
        if (!!auth) {
          this.$router.replace(this.nextRoute)
        }
      }
    },
    methods: {
      async signIn(email, password) {
        await this.$auth.signin(this.email, this.password)
        .then(function() {
          // User deleted.
          // alert('User deleted')
        }).catch(function(error) {
          // An error happened.
          alert('An error happened: ' + error)
        });
        
      },
    }
  }
</script>
<style scoped>
  .sign-in-card {
    background-color: #fafafa;
  }
  .error-note {
    color: #999;
    font-size: 12px;
  }
  

  input {
    background-color: #fff;
    border: 1px solid #000;
    padding: 2px 10px;
  }
      span.inner {
        color: black;
        font-size: 16px;
        font-weight: normal;
    }
    span.outer {
        color: red;
        text-decoration: line-through;
        font-weight: bold;
        font-size: 20px;
    }
</style>