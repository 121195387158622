import store from './store'
import Firebase from 'firebase'
// import { stringify } from 'querystring';
// import { async } from 'q';

const config = {
  apiKey: "AIzaSyCutXgCfzFk7ZaGTa6ctJ5h6iQmPEtDK4I",
  authDomain: "factory-settings.firebaseapp.com",
  databaseURL: "https://factory-settings.firebaseio.com",
  projectId: "factory-settings",
  storageBucket: "factory-settings.appspot.com",
  messagingSenderId: "456563233625"
}

export default {
  install: (Vue, options) => {
    const firebase = Firebase.initializeApp(config)
    const auth = firebase.auth()
    const db = firebase.database()
    const totDlRef = db.ref("totDlRef");
    const userRef = db.ref("userRef");

    // !! appVerificationDisabledForTesting !! 
    firebase.auth().settings.appVerificationDisabledForTesting = false

    Vue.prototype.$auth = {

      signup: async (email, password) => {
        return await auth.createUserWithEmailAndPassword(email, password)
      },
      verifyEmail: async () => {
        var user = firebase.auth().currentUser
        return await user.sendEmailVerification()
      },
      signin: async (username, pass) => {
        return await auth.signInWithEmailAndPassword(username, pass)
      },
      forgot: async (email) => {
        return await auth.sendPasswordResetEmail(email)
      },
      logout: async () => {
        await auth.signOut()
      },
      deleteAccount: async () => {
        var user = firebase.auth().currentUser
        return await user.delete()
      }
    }

    auth.onAuthStateChanged(user => {
      store.commit('updateUser',{ user })
    })  

    Vue.prototype.$db = {

      registerDownload:async(uid) => {
        return await userRef.child(uid).push(
          {
            timestamp: String(Math.floor(Date.now() / 1000))
          }
        )
      },

      readTotalDownloads:async() => {
        return await totDlRef.on("value", 
          function(snapshot) {
            console.log( snapshot.val())
            return 10
            // alert('joe')
          },
          function (errorObject) {
            console.log("The read failed: " + errorObject.code);
        })
      },

      setDownloadInfo: async(i) => {
        return await totDlRef.set({
          downloadTotals: i
        })
      },

      addSomething: async() => {
        return await libRef.set({
          roodkapje: {
            duration: 45,
            script: "Alan Turing"
          },
          1984: {
            duration: 360,
            script: "Tori Niman"
          }
        })
      },
      readSomething: async() => {
        return await libRef.on("value", 
          function(snapshot) {
            console.log( snapshot.val())
            alert('joe')
          },
          function (errorObject) {
            console.log("The read failed: " + errorObject.code);
        })
      },
      updateSomething: async() => {
        return await libRef.child('roodkapje').update({ duration: 55});
      },
      deleteSomething: async() => {
        return await libRef.child('alanisawesome').remove()
      }
    }
  }
}