<template>
  <v-container>
    <div v-if="!!user">
      <Mpftle class="adjust-height" />
      <br>
      <p>We don't upload files to our servers. The conversion is done locally in your browser. We do register usage.</p>
    </div>
    <div v-else>
      <strong>You are not logged in</strong>
      <p>We are sorry you need to sign up to use MPFTLE</p>
      <p>Sign in or sign up <router-link to="/account/signin">here</router-link>
      </p>
    </div>
  </v-container>
</template>
<script>
  import Mpftle from './Mpftle.vue'
  import {
    mapGetters
  } from 'vuex'
  export default {
    components: {
      Mpftle
    },
    computed: {
      ...mapGetters(['user']),
    },
  }
</script>
<style>
.adjust-height {
  margin-top:8px;
}
    .mpftle {
        background-color: #333;
        padding: 0;
    }
    /* PANEL FOR ALL NOTE TRACKS */
    .noteTracks {
        background-color: #112;
        /* padding: 2px 0; */
        /* width: 98%; */
        overflow-x: auto;
        white-space: nowrap;
        min-height: 300px;
        z-index: 1;
        /* padding-bottom: 10px; */
    }
    /* PANEL FOR THE NOTE TRACK */
    .noteTrack {
        margin: 0;
        padding: 0;
        bottom: 0;
    }
    .noteTrack:nth-child(even) {
        background-color: #333;
    }
    .noteTrack:nth-child(odd) {
        background-color: #222;
    }
    .noteTrackHeader {
        /* background: #645; */
        min-width: 100px;
        color: #ddd;
        padding: 0 5px;
        /* padding: 5px; */
    }
    /* .noteTrackHeader:nth-child(odd) {
        background-color: #333;
    } */
    .noteTrackHeader:nth-child(even) {
        background-color: #def;
    }
    .frameBlock {
        display: inline-flex;
        /* display: inline; */
        bottom: 0px;
        /* width: 10px; */
        height: 40px;
    }
    .frameBlock:nth-child(even) {
        /* height: 40px; */
        background-color: #666;
    }
    .frameBlock:nth-child(odd) {
        background-color: #555;
    }
    .export {
        background-color: rgb(167, 20, 20);
    }
    .frameValue {
        background-color: #0f0;
        /* display: inline-block; */
        bottom: 0px;
        /* margin-left: 49%; */
        width: 12px;
        margin: 0 25%;
    }
    .bringToFront {
        z-index: 10;
    }
    .subheader {
        font-size: 9px;
    }
    .edit-input {
        background-color: #112;
        color: #0f0;
        text-align: right;
        width: 50px;
    }
    .edit-header {
        background-color: #555;
        margin-right: 10px;
        padding-left: 5px;
    }
    .my-tool-tip {
        font-size: 10px;
        background-color: #FFF9C4;
        color: #112;
    }
    .loader {
        /* position: absolute; */
        z-index: 100;
        width: 87%;
    }
    .row1 {
        background-color: #333;
        overflow-x: auto;
        white-space: nowrap;
    }
</style>