<template>
    <v-container class="">
        <v-row no-gutters>
            <v-col cols="12" sm="6" offset-sm="3" centered>
                <div class="mt-10">
                    <h1>Join!</h1>
                    <p>
                        Our fascination for visualsonification and audiovisualisation lets us deal with the tools we
                        like. Pushing
                        the boundaries between CGI and music.
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    export default {

    }
</script>

<style>

</style>