// import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using 
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify, {
    iconfont: 'md',
});

export default new Vuetify({
});
