<template>
    <div>
        <!-- BEGIN :: CONTAINER -->
        <!-- {{ isLoading ? 'true' : 'false'}} -->
        <v-container class="mpftle">
            <!-- BEGIN :: TOOLBAR -->
            <div dark>
                <v-toolbar dense color="#444" flat class="mb-3">
                    <v-toolbar-title class="mr-2"><strong>MPFTLE</strong></v-toolbar-title>

                    

                    <input type="file" ref="file" style="display: none" @change='onFilePicked'>
                    <v-tooltip right attach content-class="my-tool-tip" z-index="10">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click='pickFile' v-model='fileName'>
                                <span>Open</span><!-- <v-icon>insert_drive_file</v-icon> -->
                            </v-btn>
                        </template>
                        <span>Open file</span>
                    </v-tooltip>

                    <v-tooltip right attach content-class="my-tool-tip">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="dialog=true" :disabled="!canDownload">
                                <span>Save</span>
                                <!-- <v-icon>save_alt</v-icon> -->
                            </v-btn>
                        </template>
                        <span>Save file</span>
                    </v-tooltip>
                    <v-flex d-flex>
                        <div dark tile flat>

                            <base-bpm />

                            <base-fps />

                        </div>
                    </v-flex>
                    <v-flex d-flex>
                        <div tile dark flat>
                            <span class="edit-header">Attack: <input class="edit-input" type="number"
                                    v-model="envAttack" min="0" max="1000"></span>
                            <span class="edit-header">Decay: <input class="edit-input" type="number" v-model="envDecay"
                                    min="0" max="1000"></span>
                            <span class="edit-header">Sustain: <input class="edit-input" type="number"
                                    v-model="envSustain" min="0" max="100"></span>
                        </div>
                    </v-flex>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </div>
            <!-- END :: TOOLBAR -->

            <!-- BEGIN :: SEQUENCER CONTAINER -->
            <v-container>

                <!-- BEGIN :: TRACKS -->
                <v-row class="row1" no-gutters>
                    <v-col class="noteTracks">
                        <div v-for="(trackList, index) in flattenList" :key="index" class="noteTrack">

                            <base-track :index=index :trackList="trackList" />

                        </div>
                    </v-col>
                </v-row>
                <!-- END :: TRACKS -->

                <!-- BEGIN :: LOG -->
                <base-log :mpftleLog="mpftleLog" />
                <!-- END :: LOG -->

            </v-container>
            <!-- END :: SEQUENCER CONTAINER -->
            <!-- BEGIN :: DIALOG -->
            <v-layout row justify-center>
                <v-dialog v-model="dialog" persistent max-width="500px" max-height="300px">
                    <v-card dark>
                        <v-card-title>
                            <span class="headline">Click on the file extension you need.</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12>
                                        <!-- <v-select attach :items="['.txt','.csv','.jsx']"></v-select> -->
                                        <v-btn @click="saveTextFile">Text file</v-btn><br>
                                        <v-btn @click="saveCsvFile">Csv file</v-btn><br>
                                        <v-btn @click="saveJsxFile">Jsx file</v-btn><br>
                                        <v-btn @click="savePyFile">Blender file</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <!-- <small>*How do you like that?</small> -->
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" flat @click="dialog = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
            <!-- END :: DIALOG -->
        </v-container>
        <!-- END :: CONTAINER -->

        <base-filepicker  />

    </div>
</template>

<script>
    const midiParser = require('./../../../node_modules/midi-parser-js/src/midi-parser')
    import {
        mapGetters,
        mapState,
        mapActions
    } from 'vuex'
    export default {
        name: 'Mpftle',
        data: function () {
            return {
                fileName: '',
                midiUrl: '',
                midiFile: '',
                parsedMidiFile: '',
                convertedMidiObject: '',

                msMinute: 60000,
                totalTicks: 0,
                showResult: true,
                defaultMidiTrack: 0,
                envelope: 0,
                lorem: "Lorem ipsum",
                mpftleLog: "Thanks for using Midi Power Frame Tool Lite Edition\n" +
                    "Version beta 0.2.2!\n",
                dialog: false,
                timePerFrame: 0,
                saveFileName: '',

                envAttack: 0,
                envDecay: 3,
                envSustain: 0,
                envRelease: 0,

                bpmHasChanged: false,
                midiStringObject: null,
                myDoubleMidiObject: null,
                calculatedProgress: 0.0,
                loading: false
                // totDl: 'not set'
            }
        },
        mounted() {
            // this.registerDownload(user.uid)
        },
        created() {
            // this.$log.info('MPFTLE RUNNING')
            // alert("MPFTLE")
        },
        computed: {
            
            ...mapGetters(['user', 'progress', 'bpm', 'fps', 'isLoading']),
            ...mapActions(['updateBpm', 'setLoadingFalse', 'setLoadingTrue']),
            // ...mapGetters(['user', 'progress']),
            canDownload() {
                return this.convertedMidiObject != ''
            },
            miliseconds () {
                return this.msMinute / this.bpm
            },
            flattenList () {
                // org [ [0,0 ], [1,2] , [2,1] , [3,4], [4,3]  ]
                // new [ [0,1,2,3,4], [0,2,1,4,3] ]
                var result = []
                
                console.log('Before flatten')
                // alert("Render view started")
                
                
                this.calculatedProgress = 0
                if (this.convertedMidiObject.length > 0) {
                    // this.$log.info(this.convertedMidiObject.length)  
                    var x = this.convertedMidiObject.length;
                    var y = this.convertedMidiObject[0].length;
                    for (let j = 0; j < y; j++) {
                        result[j] = [];
                        for (let i = 0; i < x; i++) {
                            result[j].push(this.convertedMidiObject[i][j]);
                        }
                    }
                }
                // alert("Render view ended")
                
                console.log('After flatten')
                console.log(this.isLoading)
                return result
            },
        },
        watch: {
            bpm: function () {
                if (this.bpm >= 30) {
                    this.mpftleLog = ''
                    
                    this.onConvertFile()
                    
                }
            },
            fps: function () {
                if (this.fps > 9) {
                    this.mpftleLog = ''
                    this.onConvertFile()
                }
            },
            envelope: function () {
                if (this.envelope >= 0) {
                    this.mpftleLog = ''
                    this.onConvertFile()
                }
            },
            envAttack: function () {
                if (this.envelope >= 0) {
                    this.mpftleLog = ''
                    this.onConvertFile()
                }
            },
            envDecay: function () {
                if (this.envelope >= 0) {
                    this.mpftleLog = ''
                    this.onConvertFile()
                }
            },
            envSustain: function () {
                if (this.envelope >= 0) {
                    this.mpftleLog = ''
                    this.onConvertFile()
                }
            },
            user: function () {
                // LELIJK !!!
            },
            calculatedProgress: function () {

                console.log('Update progress bar pls')
            }
        },
        methods: {
            map_range(value, low1, high1, low2, high2) {
                return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
            },
            async onFilePicked(e) {
                // alert('hallo')
                
                const files = e.target.files
                var el = this
                if (files[0] !== undefined) {
                    this.fileName = files[0].name
                    // console.log(files[0])
                    this.saveFileName = (files[0].name).split('.').slice(0, -1).join('.')
                    if (this.fileName.lastIndexOf('.') <= 0) {
                        return
                    }
                    
                    this.readFile(files[0])
                    
                } else {
                    this.fileName = ''
                    this.midiFile = ''
                    this.midiUrl = ''
                }
            },
            readFile(e) {
                
                const fr = new FileReader()
                fr.readAsDataURL(e)
                
                fr.onload = () => {
                    console.log('loading...')

                    // el.$store.dispatch('updateProgress')
                    this.midiUrl = fr.result
                    this.midiFile = e
                    this.parsedMidiFile = midiParser.parse(fr.result)
                    this.mpftleLog = 'Start Loading \n'

                    this.onConvertFile()
                    
                    this.mpftleLog = 'End Loading \n'
                }
                
                fr.addEventListener('progress', () => {
                    
                    console.log('Checking progress')
                    
                })
                fr.addEventListener('loadend', () => {
                    
                    console.log('Klaar met laden')
                    
                    
                })
                
            },
            async onConvertFile() {
                
                
                console.log('Starting midi parsing.')

                const midiOutput = this.parsedMidiFile
                const timeDivision = midiOutput.timeDivision
                const myTrack = midiOutput.track[this.defaultMidiTrack]
                const midiOutputTrackEvents = myTrack.event
                console.log(midiOutputTrackEvents.length)
                var msTick = this.miliseconds / timeDivision
                var deltaTime = 0
                var totalTicks = 0
                // This part is used to get 
                // some variables which
                // will be used to calculate total time
                for (let index = 0; index < midiOutputTrackEvents.length; index++) {
                    var midiOutputTrackEvent = midiOutputTrackEvents[index]
                    deltaTime = midiOutputTrackEvent.deltaTime
                    totalTicks = totalTicks + deltaTime
                    // add real tick to midi object so we can easily acces it instead of calculate it
                    midiOutputTrackEvent.realTick = totalTicks
                }
                var myTime = totalTicks * msTick
                var myTotalTime = (myTime / 1000).toFixed(2)
                this.totalTime = myTotalTime
                var framesNeeded = Math.round(this.fps * (myTime / 1000))
                this.timePerFrame = (framesNeeded / myTime)
                this.addLog("Total time: " + myTotalTime + "s.")
                this.addLog("Frames needed: " + framesNeeded)
                this.addLog("Time per frame: " + (framesNeeded / myTime))
                this.addLog("Ms. per tick: " + msTick)
                this.addLog("Total Ticks: " + totalTicks)
                // Calculate and set the frame number at realtick
                this.calculatedProgress = 0
                console.log('Calculate new object variabeles')
                for (let index = 0; index < midiOutputTrackEvents.length; index++) {
                    var selected = midiOutputTrackEvents[index];
                    var myFpsValue = (Math.round((selected.realTick * msTick) / 1000 * this.fps))
                    selected.realTick = myFpsValue

                }
                this.midiStringObject = JSON.stringify(midiOutputTrackEvents, undefined, 2)
                // Search for distinct key / note / cc channels
                var distinctChannels = []
                for (let index = 0; index < midiOutputTrackEvents.length; index++) {
                    var e = midiOutputTrackEvents[index];
                    if (e.type == 9) { // Only Note on for now
                        var myKeyNoteOn = e.data[0]
                        distinctChannels.push(myKeyNoteOn)
                    }
                    if (e.type == 11) { // Only Note on for now
                        var myCCData = e.data[0]
                        distinctChannels.push(myCCData)
                    }
                    // Note off en CC mogen erbij
                }
                // Keep distinct channels
                distinctChannels = [...new Set(distinctChannels)];
                // Constructing new object that is more easy to iterate over
                console.log('Start new object')
                this.calculatedProgress = 50
                console.log(this.calculatedProgress)
                var midiTrackEvents = []
                for (let distinctChannel = 0; distinctChannel < distinctChannels.length; distinctChannel++) {
                    var selectedChannel = distinctChannels[distinctChannel];
                    var _noteOn = []
                    var _noteOff = []
                    var _CC = []
                    for (let index = 0; index < midiOutputTrackEvents.length; index++) {
                        var selectedMidiOutputTrackEvent = midiOutputTrackEvents[index];
                        // NOTE ON
                        if (selectedMidiOutputTrackEvent.type == 9) {
                            if (selectedMidiOutputTrackEvent.data[0] == selectedChannel) {
                                _noteOn.push(selectedMidiOutputTrackEvent.realTick)
                            }
                        }
                        // NOTE OFF
                        if (selectedMidiOutputTrackEvent.type == 8) {
                            if (selectedMidiOutputTrackEvent.data[0] == selectedChannel) {
                                _noteOff.push(selectedMidiOutputTrackEvent.realTick)
                            }
                        }
                        // MIDI CC
                        if (selectedMidiOutputTrackEvent.type == 11) {
                            if (selectedMidiOutputTrackEvent.data[0] == selectedChannel) {
                                _CC.push({
                                    frame: selectedMidiOutputTrackEvent.realTick,
                                    data: selectedMidiOutputTrackEvent.data[1]
                                })
                                // _CC.push('data')
                            }
                        }
                    }
                    midiTrackEvents.push({
                        key: selectedChannel,
                        noteOnFrame: _noteOn,
                        noteOffFrame: _noteOff,
                        midiCC: _CC
                    })
                }
                console.log('New object')
                this.calculatedProgress = 75
                this.myDoubleMidiObject = JSON.stringify(midiTrackEvents, undefined, 2)
                // console.log(midiTrackEvents)
                // iterate over all frames in all tracks
                var rawScore = []
                for (let currentFrame = 0; currentFrame <= framesNeeded; currentFrame++) {
                    // iterate over all tracks in midiTrackEvents
                    var result = []
                    for (let midiTrackEventIndex = 0; midiTrackEventIndex < midiTrackEvents
                        .length; midiTrackEventIndex++) {
                        var currentMidiTrackEventIndex = midiTrackEvents[midiTrackEventIndex];
                        var frameList = currentMidiTrackEventIndex.noteOnFrame
                        var ccList = currentMidiTrackEventIndex.midiCC[currentFrame]
                        result[midiTrackEventIndex] = 0
                        if (ccList) {
                            var r = ccList.data
                            r = this.map_range(r, 0, 127, 0, 100)
                            result[midiTrackEventIndex] = r
                        }
                        // Iterate over all frames with NOTES               
                        for (let index = 0; index < frameList.length; index++) {
                            var noteOnFrame = currentMidiTrackEventIndex.noteOnFrame[index]
                            var noteOffFrame = currentMidiTrackEventIndex.noteOffFrame[index]
                            var _maxVelocity = 100
                            var _currentFrame = currentFrame
                            var _noteOnFrame = noteOnFrame
                            var _noteOffFrame = noteOffFrame
                            var _envAttack = parseInt(this.envAttack)
                            var _envDecay = parseInt(this.envDecay)
                            var _envSustain = parseInt(this.envSustain)
                            var _envRelease = parseInt(this.envRelease)
                            var _noteOnAttackMax = _noteOnFrame + _envAttack
                            var _noteOnDecay = _noteOnAttackMax
                            var _noteOnDecayMax = _noteOnAttackMax + _envDecay
                            // STUFF FOR NOTES ONLY
                            if (this.envAttack == 0) {
                                if (currentFrame == noteOnFrame) {
                                    result[midiTrackEventIndex] = this.envSustain
                                }
                            } else {
                                if ((_currentFrame >= _noteOnFrame) &&
                                    (_currentFrame <= _noteOnAttackMax) &&
                                    (_currentFrame <= _noteOffFrame)) {
                                    var calculateFrame = _currentFrame - _noteOnFrame + 1
                                    var dividerSteps = _envAttack + 1
                                    // +1 is needed to convert frame 0 to frame 1
                                    var _velocity = _maxVelocity / dividerSteps * calculateFrame
                                    if (_velocity >= _maxVelocity) {
                                        _velocity = _maxVelocity
                                    }
                                    if (_currentFrame == _noteOnDecay) {
                                        result[midiTrackEventIndex] = _velocity
                                    }
                                    result[midiTrackEventIndex] = _velocity
                                }
                            }
                            // This is putting values for sustain
                            if ((_currentFrame > (_noteOnDecayMax)) &&
                                (_currentFrame < _noteOffFrame)) {
                                result[midiTrackEventIndex] = _envSustain
                            }
                            // This is calculating values when decay envelope is not 0
                            if ((_currentFrame >= _noteOnDecay) &&
                                (_currentFrame <= _noteOnDecayMax) &&
                                (_currentFrame < _noteOffFrame) &&
                                (_envDecay > 0)) {
                                var _envelopePart = (_maxVelocity - _envSustain) / _envDecay
                                var _velocity = _maxVelocity - (((_currentFrame) - _noteOnDecay) * _envelopePart)
                                if (_velocity <= _envSustain) {
                                    _velocity = _envSustain
                                }
                                result[midiTrackEventIndex] = _velocity
                                // console.log(_velocity)
                                if (_currentFrame == _noteOnDecay) {
                                    _velocity = _maxVelocity
                                    result[midiTrackEventIndex] = _velocity
                                }
                            }

                        }
                    }
                    rawScore.push(result)
                }

                console.log('New object klaar')
                this.calculatedProgress = 100
                this.convertedMidiObject = rawScore
                // END OF MAIN MIDI CONVERTING FUNCTION
            },
            addLog(s) {
                this.mpftleLog += s
                this.mpftleLog += "\n"
            },
            pickFile() {
                this.$refs.file.click()
            },
    
            saveOptions() {
                this.dialog = true
            },
            saveTextFile() {
                const data = this.convertedMidiObject
                var dataText = ''
                for (let i = 0; i < data.length; i++) {
                    const dataRow = data[i];
                    dataText = dataText + dataRow + '\n'
                }
                const blob = new Blob([dataText], {
                    type: 'text/plain'
                })
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = this.saveFileName + ".txt";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                this.registerDownload(this.user.uid)
                // this.setDownloadInfo()
            },
            saveCsvFile() {
                const data = this.convertedMidiObject
                var dataText = ''
                for (let i = 0; i < data.length; i++) {
                    const dataRow = data[i];
                    dataText = dataText + dataRow + '\n'
                }
                const blob = new Blob([dataText], {
                    type: 'text/plain'
                })
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = this.saveFileName + ".csv";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                this.registerDownload(this.user.uid)
                // this.setDownloadInfo()
            },
            saveJsxFile() {
                const data = this.flattenList
                var dataText = "var compName = 'MPFTLE - export';" + "\n";
                dataText += "var compWidth = 720;" + "\n";
                dataText += "var compHeight = 486;" + "\n";
                dataText += "var compAspectRatio = 1;" + "\n";
                dataText += "var compDuration = " + this.totalTime + ";\n";
                dataText += "var compFrameRate = " + this.fps + ";\n";
                dataText += "var compBackGroundColor = [0,0,0];" + "\n";
                dataText += "var myItemCollection = app.project.items;" + "\n";
                dataText +=
                    "var myComp = myItemCollection.addComp(compName,compWidth,compHeight,compAspectRatio,compDuration,compFrameRate);" +
                    "\n";
                dataText += "myComp.bgColor = compBackGroundColor;" + "\n";
                // this.$log.info(data.length)
                for (let i = 0; i < data.length; i++) {
                    const dataRow = data[i];
                    dataText += "mySolid" + i + " = myComp.layers.addSolid([1,1,1], 'Track " + i + 1 +
                        "', 100, 100, 1, " + this.totalTime + ");" + "\n";
                    dataText += "var myOpacity" + i + " = mySolid" + i + ".property('opacity');" + "\n";
                    for (let j = 0; j < dataRow.length; j++) {
                        const element = dataRow[j];
                        dataText = dataText + 'myOpacity' + i + '.setValueAtTime(' + (j * (1 / this.fps)) + ", " +
                            element + ');' + '\n'
                    }
                }
                //  Write / download
                const blob = new Blob([dataText], {
                    type: 'text/plain'
                })
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = this.saveFileName + ".jsx";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                this.registerDownload(this.user.uid)
                // this.setDownloadInfo()
            },
            savePyFile() {
                const data = this.flattenList
                var dataText = "import bpy" + "\n";
                dataText += "bpy.context.scene.render.fps = " + this.fps + "\n";
                // this.$log.info(data.length)
                for (let i = 0; i < data.length; i++) {
                    const dataRow = data[i];
                    dataText += "# Go frame 0" + "\n";
                    dataText += "bpy.context.scene.frame_set(0)" + "\n";
                    dataText += "# Add cube" + "\n";
                    dataText += "bpy.ops.mesh.primitive_cube_add(location=(0, " + i * 2 + ", 0))" + "\n";
                    dataText += "# Init" + "\n";
                    // dataText += "bpy.context.object.name = "Name""
                    dataText += "bpy.context.object.scale[0] = 0" + "\n";
                    dataText += "bpy.context.object.scale[1] = 0" + "\n";
                    dataText += "bpy.context.object.scale[2] = 0" + "\n";
                    dataText += "# Add Keyframe" + "\n";
                    dataText += "bpy.ops.anim.keyframe_insert_menu(type='Scaling')" + "\n";
                    dataText += "# Change Stuff" + "\n";
                    // dataText += "mySolid" + i + " = myComp.layers.addSolid([1,1,1], 'Track " + i + 1 +
                    // "', 100, 100, 1, " + this.totalTime + ");" + "\n";
                    // dataText += "var myOpacity" + i + " = mySolid" + i + ".property('opacity');" + "\n";
                    for (let j = 0; j < dataRow.length; j++) {
                        const element = dataRow[j];
                        dataText += "# Go other keyframe" + "\n";
                        dataText += "bpy.context.scene.frame_set(" + j + ")" + "\n";
                        dataText += "# Change stuff on keyframe" + "\n";
                        dataText += "bpy.context.object.scale[0] = " + element / 100 + "\n";
                        dataText += "bpy.context.object.scale[1] = " + element / 100 + "\n";
                        dataText += "bpy.context.object.scale[2] = " + element / 100 + "\n";
                        dataText += "# Add keyframe" + "\n";
                        dataText += "bpy.ops.anim.keyframe_insert_menu(type='Scaling')" + "\n";
                    }
                }
                //  Write / download
                const blob = new Blob([dataText], {
                    type: 'text/plain'
                })
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = this.saveFileName + ".py";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                this.registerDownload(this.user.uid)
                // this.setDownloadInfo()
            },
            async setDownloadInfo() {
                this.confirmationResult = await this.$db.setDownloadInfo(200)
                    .then(function (confirmationResult) {
                        return confirmationResult
                    })
            },
            async registerDownload(i) {
                this.confirmationResult = await this.$db.registerDownload(i)
                    .then(function (confirmationResult) {
                        return confirmationResult
                    })
            }
        }
    }
</script>