<template>
    <div>
        <h1>BPM: {{ a }}</h1>
        <input v-model="bpm">
    </div>
</template>
<script>
export default {
    name: 'BaseTest',
    computed: {
        bpm: {
            get() {
                return this.$store.state.bpm
            },
            set(value) {
                this.$store.commit('updateBpm', value)
            }
        }
    }
}
</script>
